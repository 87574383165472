<template>
	<div style="display: flex;flex-direction: column;align-items: center;justify-content: center;" class="font-famliy">
		<div class="zxyj-content-box" >
			<div class="banner-content">
				<div class="banner">
						<img style="height: 400px;" src="@/assets/images/banner/news.png" alt="加载失败" />
				</div>
			</div>
		</div>
		<div class="news" style="display: flex;justify-content: center;width: 100%; background-color: #f5f4f4;">
			<div class="xzyj-content" style="display: flex;">
				<div class="news_list">
					<div style="display: flex;align-items: center;margin-top: 30px;margin-left: 15px;">
						<el-breadcrumb separator="/">
						 <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
						 <el-breadcrumb-item >{{route.meta.title}}</el-breadcrumb-item>
						</el-breadcrumb>
					</div>
					<div style="padding: 30px 0px 0px 0px;">
						<a class="news-right-content" v-for="(item, i) in news" :key="i" :href="['/newsDetail/index.html?id='+item.id+'&type=news']" onclick="window.open(this.href);return false">
							<div class="content-body">
								<div class="news-right-content-right">
									<img style="height: 100%;width: 100%;" :src="item.image" alt="加载失败" />
								</div>
								<div class="news-right-content-left">
									<div class="news-title" style="">{{item.title}}</div>
									<div class="news-des">{{item.subTitle}}</div>
									<div class="news-time">{{item.date}}</div>
									<!-- <div class="news-detail">
										<a style="" :href="['/newsDetail/index.html?id='+item.id+'&type=news']">详情</a>
									</div> -->
								</div>
							</div>
						</a>
						<div class="page" style="text-align: center;margin-top: 60px;">
							<el-pagination background layout="prev, pager, next" :page-size="params.pageSize" :total="total" @current-change="
									(val) => {
										changePage(val);
									}
								">
							</el-pagination>
						</div>
					</div>
					
				</div>
				<!-- <div class="hotNews">
					<div class="title">热点新闻</div>
					<div style="margin-top: 5%;">
						<div v-for="(item, i) in navs" :key="i" style="margin-top: 2%;">
							<a class="a-h" :href="[url+item.id+'&type='+type]">
								{{item.newsTitle}}
							</a>
						</div>
					</div>
				</div> -->
			</div>
			<div style="height: 100px"></div>
		</div>
		
	</div>
</template>

<script>
	import http from "@/common/utils/Http";
	export default {
		metaInfo: {
			title: "公司新闻动态-鑫昇腾",
			meta:[
				{name:"keywords", content: "爬架新闻,建筑爬架新闻,智能爬架新闻,全钢爬架新闻"},
				{name:"description", content: "江苏鑫昇腾科技发展股份有限公司专注于建筑爬架的设计、制造和专业承包;提供爬架租赁、销售、运营、安全监管、技术服务等最新新闻动态。"}
			]
			
		},
		data() {
			return {
				news: [],
				total: 0,
				navs:[],
				route: this.$route,
				params: {
					pageNum: 1,
					pageSize: 10
				},
			};
		},
		activated() {
			this.getNews();
			this.getHot();
		},
		methods: {
			getNews() {
				let params = this.params;
				http.api.get("/site/news/list", {
					params
				}).then((res) => {
					this.news = this.changeArr(res.data.rows);
					this.total = res.data.total;
				});
			},
			getHot(){
				let params = this.params;
				params.hot='1'
				http.api.get("/site/news/list", {
					params
				}).then((res) => {
					this.navs = res.data.rows;
					this.total = res.data.total;
				});
			},
			changePage(val) {
				this.params.pageNum = val;
				this.getNews();
			},
			// 转换新闻
			changeArr(arr) {
				let newArr = [];
				arr.forEach((row) => {
					let sub = {};
					// const date = row.createTime.split("-");
					// const day = date[2].split(" ")[0];
					// sub.day = day;
					// sub.month = date[1];
					const date = row.createTime.split("-");
					const day = date[2].split(" ")[0];
					sub.day = day;
					sub.month = date[1];
					sub.year=date[0];
					sub.date=sub.year+"."+sub.month+"."+sub.day;
					sub.title = row.newsTitle;
					sub.subTitle = row.newsSubtitle;
					sub.image = row.newsUrl;
					sub.id = row.siteNewsId;
					newArr.push(sub);
				});
				return newArr;
			},
			// 打开详情页
			detail(id) {
				this.$router.push({
					path: "news_Detail",
					query: {
						type: "news",
						id: id
					},
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
.font-famliy  {
		div,a,span {font-family: MicrosoftYaHei;}
}
.banner-content {	margin-top: 80px;	position: relative;	height: 400px;width: 100%;}
.banner {	height: 100%;	width: 100% !important;	overflow: hidden;	text-align: center;	margin: 0 auto;	padding: 0;	z-index: 1;}
	.news_list {
		margin: 0 0 30px;
		width: 1200px;
		// background: #FFFFFF;

		.list {
			margin: 0 auto;
			width: 70%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-bottom: 1px solid #e8e8e8;
			padding: 4px;
			font-size: 0.9rem;

			.time {
				width: 4rem;

				.day {
					font-size: 1.1rem;
					text-align: center;
				}

				.month {
					font-size: 0.8rem;
					text-align: center;
					color: #636363;
				}
			}

			.content {
				font-weight: 400;
			}

			.image {
				width: 100px;
			}
		}
	}

	.page {
		text-align: right;
		margin: 20px;
	}

	.news-right-content{
		display: block;
		height: 200px;
		background-color: white;
		margin-top: 20px;
		box-shadow: 0px 2px 10px 0px rgba(0, 4, 20, 0.15);
		border-radius: 2px;
		.content-body {
			display:flex;
			align-items: center;
			justify-content:space-between;
			height: 200px;
			padding: 10px;
		}
	}
	.news-right-content-left{
		width: 800px;
		line-height: 30px;
		display: flex;
		flex-direction: column;
		height: 140px;
		line-height: 30px;
		justify-content: space-between;
		margin-left: 20px;
	}
	.news-right-content-right {
		width: 360px;
		height: 150px;
	}
	.hotNews {
		width: 290px;
		height: 380px;
		background-color: white;
		margin-left: 10px;
		padding: 20px;
		margin-top: 65px;
        
        .a-h:hover {
        	color: #20A0FF;
        }
		.title { 
			border-bottom: 1px solid #E5E5E5;
			padding-bottom: 10px;
			margin-right: -20px;
			position: relative;
			font-size: 18px;
			font-weight: bold;
		}
		.title:after{
			content: '';
			height: 1px;
			width: 88px;
			background: #0078FF;
			position: absolute;
			left: 0;
			bottom: 0;
		}
	}
	.news-title {
		font-size: 24px;
		font-weight: 600;
		font-family: MicrosoftYaHeiSemibold;
	}
	.news-right {
		width: 660px;
		height: 470px;
	}
	.news-time {
		font-size: 14px;
		font-family: MicrosoftYaHei;
		color: #999999;
		line-height: 20px;
	}
	.news-des{
		font-family: MicrosoftYaHei;
		font-size: 14px;
		line-height: 20px;
		height: 70px;
		text-overflow: ellipsis;
		padding: 10px 10px 0 0;
		/* white-space: nowrap; */
		-webkit-line-clamp: 3;
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
	}
	.news-detail {
		display: flex;
		justify-content: flex-end;
		padding-right: 30px;
		
		 a{
			height: 30px;
			width: 60px;
			background-color: #0078ff;
			display: block;
			text-align: center;
			color: white;
		 }

	}
</style>
